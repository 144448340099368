body {
  background-color: #333;
  color: #fff;
  font-family: monospace;
  font-size: x-large;
  margin-left: 3em;
  margin-right: 3em;
}

.latest-posts {
  display: flex;
  justify-content: space-between;
}

.post-card {
  background-color: #444;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.post-content {
  margin-top: 20px;
}

.title-wrapper {
  display: flex;
  justify-content: center;
}

button {
  background-color: #555;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-family: monospace;
}

a {
  text-decoration: none;
}

.latest-posts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cards {
  display: flex;
  gap: 20px;
}

.see-all-posts-button {
  margin-top: 20px; 
  align-self: flex-start; 
}

.all-posts {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: 20px;
}

#heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: fit-content;
  animation: revealText 3s ease-in-out;
  margin: 0 auto;
}

#welcome {
  white-space: nowrap;
}

@keyframes revealText {
  from { width: 0%; }
  to { width: 100%; }
}
